.samaj {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #272727;
  padding: 20px;
}
.users {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 22px; */
  /* identical to box height */

  color: #717579;
}
.users2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 22px; */
  /* identical to box height */

  /* color: #717579; */
}
.usersDetails {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* line-height: 22px; */
  /* identical to box height */

  /* Main Color 2 */
  color: #39475f;
}
.menuItem {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #717579;
}
.findPeople {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  color: #303134;
}
.header-background {
  background: #fbfbfb;
  border-radius: 2px;
  border-bottom: 2px solid #eeeeee;
}
.totalPeople {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 40px;
}
.table {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  text-align: right;
  color: #000000;
  overflow-y: scroll;
}
.AdminTable ::-webkit-scrollbar {
  display: none !important;
}
.ant-table-thead {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #000000;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 5px solid #d7d7d7;
}
.ant-table-table {
  width: 100%;
  border-radius: 2px 2px 0 0;

  border-collapse: separate;

  border-spacing: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.mainmenu {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding: 20px;
  color: #d7d7d7;
}
.firstname {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #717579;
}
.button-search {
  background: #0064ff !important;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  line-height: 17px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  width: 150px;
}
.button-search.ant-btn-primary:focus {
  background: #0064ff !important;
}
.ant-badge-count {
  background: #0099ff;
  border-radius: 50%;
}
.search {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

@media (max-width: 767px) {
  .samaj {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0.3px;
    color: #272727;
    padding: 20px;
  }
}
.selectedTabsButton {
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #303134;
}
.unSelectedTabsButton {
  background: #edf1fb;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #717579;
}
.button-matrimonialProfile {
  background: #0064ff !important;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.div {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  height: 951px;
}
.matches {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #303134;
}
.matchesDetail {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #717579;
}
.MatchesCard {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.MatchesCard .ant-card-body {
  padding: 0 !important;
}
.View-matchName {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #303134;
}
.View-matchDate {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}
.View-matchPercentage {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #219653;
}
.View-matchAddress {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}
.View-fullProfile {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #0064ff;
  border: none;
}
.cMTabsBckground .ant-tabs-ink-bar {
  height: 0px !important;
  background: transparent !important;
}
.cMTabsBckground .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0px solid transparent !important;
}
.cMTabsBckground .ant-tabs-tab {
  padding: 0px !important;
}

.ant-table-thead {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #000000;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 5px solid #d7d7d7;
}
.ant-table-table {
  width: 100%;
  border-radius: 2px 2px 0 0;

  border-collapse: separate;

  border-spacing: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.mainmenu {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding: 20px;
  color: #d7d7d7;
}
.firstname {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #717579;
}
.button-search {
  background: #0064ff !important;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  line-height: 17px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  width: 150px;
}
.button-search.ant-btn-primary:focus {
  background: #0064ff !important;
}
.ant-badge-count {
  background: #0099ff;
  border-radius: 50%;
}
.search {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

@media (max-width: 767px) {
  .samaj {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0.3px;
    color: #272727;
    padding: 20px;
  }
}
.selectedTabsButton {
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #303134;
}
.unSelectedTabsButton {
  background: #edf1fb;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #717579;
}
.button-matrimonialProfile {
  background: #0064ff !important;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.div {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  height: 951px;
}
.matches {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #303134;
}
.matchesDetail {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #717579;
}
.MatchesCard {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.View-matchName {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #303134;
}
.View-matchDate {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}
.View-matchPercentage {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #219653;
}
.View-matchAddress {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}
.View-fullProfile {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #0064ff;
  border: none;
}
.cMTabsBckground .ant-tabs-ink-bar {
  height: 0px !important;
  background: transparent !important;
}
.cMTabsBckground .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0px solid transparent !important;
}
.cMTabsBckground .ant-tabs-tab {
  padding: 0px !important;
}

.input-header {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #717579;
}

.button-Upload {
  background: #0064ff;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* display: flex; */
  align-items: center;
  color: #ffffff;

  /* height: 25px;     */
}

.modal-buttonAdd {
  background: #0064ff;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  /* width: 147px; */
  color: #ffffff;
  /* height: 50px; */
  justify-content: center;
}
.modal-buttonAdd:hover {
  border-color: #0064ff;
  color: #0064ff;
}
.modal-buttonCancel {
  /* background: #E0E0E0; */
  border-color: #0064ff;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* height: 50px; */

  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: #0064ff;
  /* width: 147px; */
  /* margin-left: 20px; */
}
.modal-buttonCancel:hover {
  background-color: #0064ff;
  color: #ffffff;
}
.avtar {
  width: 127px;
  height: 125px;
}
.profileDetails-card {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.profile-name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #303134;
}
.profile-birthdate {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}
.profile-caste {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
.profile-address {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}
.ant-page-header-heading-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  background-color: #f5f6f8;
}
.pageHeaderDetails {
  background-color: #f5f6f8;
}
.pageHeaderSubDetails {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: #4f4f4f;
}
.ant-page-header {
  /* height: 45px ; */
  padding: 0px 20px;
}
.descriptionDetails {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  color: #4f4f4f;
}
.empty-details {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #828282;
}
.businessInformation {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}

.customLayout .ant-layout-content {
  height: 100vh !important;
  overflow: hidden !important;

  overflow-y: scroll !important;
  background-color: #ffffff !important;

  margin: 10px;
}
.customLayout ::-webkit-scrollbar {
  display: none !important;
}

.input-header {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #717579;
}

.button-Upload {
  background: #0064ff;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* display: flex; */
  align-items: center;
  color: #ffffff;

  /* height: 25px;     */
}

.modal-buttonAdd {
  background: #0064ff;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  /* width: 147px; */
  color: #ffffff;
  /* height: 50px; */
  justify-content: center;
}
.modal-buttonAdd:hover {
  border-color: #0064ff;
  color: #0064ff;
}
.modal-buttonCancel {
  /* background: #E0E0E0; */
  border-color: #0064ff;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* height: 50px; */

  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: #0064ff;
  /* width: 147px; */
  /* margin-left: 20px; */
}
.modal-buttonCancel:hover {
  background-color: #0064ff;
  color: #ffffff;
}
.avtar {
  width: 127px;
  height: 125px;
}
.profileDetails-card {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.profile-name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #303134;
}
.profile-birthdate {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}
.profile-caste {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
.profile-address {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}
.ant-page-header-heading-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  background-color: #f5f6f8;
}
.pageHeaderDetails {
  background-color: #f5f6f8;
}
.pageHeaderSubDetails {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: #4f4f4f;
}
.ant-page-header {
  /* height: 45px ; */
  padding: 0px 20px;
}
.descriptionDetails {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  color: #4f4f4f;
}
.empty-details {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #828282;
}
.businessInformation {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}

.customLayout .ant-layout-content {
  height: 100vh !important;
  overflow: hidden !important;

  overflow-y: scroll !important;
  background-color: #ffffff !important;

  margin: 10px;
}
.customLayout ::-webkit-scrollbar {
  display: none !important;
}

.inputLabel {
  /* border: 1px solid #D7D7D7; */
  box-sizing: border-box;

  border-radius: 4px;
  /* height: 50px; */
}

.inputLabel.ant-input-affix-wrapper {
  padding: 0px 2px 0px 8px;
}
.inputLabel.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}
.details {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 9px;
  color: #717579;
}
.formRows {
  padding-top: 10px !important ;
  padding-bottom: 10px !important;
}
.matrimonialProfile {
  font-family: Montserrat;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 20px;
  line-height: 29px;
  /* Primary Text */

  color: #303134;
}
.ant-modal-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;

  /* Primary Text */
  color: #303134;
}

.customScroll {
  overflow: hidden !important;
  overflow-y: scroll !important;
  /* height: 100vh ; */
  background-color: #ffffff !important;
}

.customScroll::-webkit-scrollbar {
  display: none !important;
}

.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  /* font-weight: 500; */
  /* font-size: 16px; */
  /* line-height: 22px; */
  word-wrap: break-word;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #0064ff;
}

.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 0px;
}

.editProfileMenuItem.ant-menu-item {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #717579;
  padding-left: 25px !important;
  background-color: #fbfbfb !important;
}
.ant-steps {
  display: contents;
}
.JobProfileDivider.ant-divider-horizontal {
  margin: 13px 0px !important;
}
.JobProfileCardDivider.ant-divider-horizontal {
  margin: 15px 0px 15px 0px;
}

.BusinessEstablishedDate {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */

  /* Gray 3 */

  color: #828282 !important;
}
.BusinessEstablishedDateData {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */

  /* Gray 3 */

  color: #828282 !important;
}
.BusinessLocation {
  font-family: "Montserrat" !important;

  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  /* or 171% */

  /* Gray 2 */

  color: #4f4f4f !important;
}

.BusinessLocationData {
  font-family: "Montserrat" !important;

  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  /* or 171% */

  /* Gray 2 */

  color: #4f4f4f !important;
}

.BusinessProfileAvatar .ant-avatar-string {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
  font-size: 77px !important;
}
.sidebarSamajTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #272727;
  display: flex;
  height: 100%;
  align-items: center;
}
.sliderMainMenuText {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #d7d7d7 !important;
}
.sliderMainMenuText.ant-menu-item-active {
  color: #d7d7d7;
}
.headerText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  /* Primary Text */

  color: #303134;
}

.BusinessTabs .ant-tabs {
  overflow: visible !important;
}
.SideBar .ant-layout-sider-children {
  height: 100vh !important;
}

.SearchUserDropdown {
  width: 100%;
  background: transparent;
  border: none;
  margin: 5px;
  display: flex;
  justify-content: flex-start;
}
.SearchUserDropdown:hover {
  background-color: grey;
  color: #ffffff;
}

.ImageViewMatrimonial {
  width: 100% !important;
}
.ImageViewMatrimonial .image-gallery-image {
  width: inherit !important;
  height: 200px !important;
  object-fit: cover !important;
}
