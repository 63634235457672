.MeetingCard{
    background: #FFFFFF !important;
box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12) !important;
border-radius: 4px !important;
font-family: Montserrat !important;
font-style: normal !important;
line-height: 24px !important;
color: #333333 !important;
}

