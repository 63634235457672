.unselectedButton{
    background: #EDF1FB;
    /* border-radius: 4px; */
    font-family: 'Montserrat';
    border: 0px solid transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #303134;
    min-height: 48px;
    min-width: 150px;
}


.selectedButton{
   
    background: #FFFFFF !important;
    box-shadow:  0px 5px 10px  #e3e3e3 !important;
    border: 0px solid transparent !important;
/* transform: scale(1.05,1.05) !important; */
border-radius: 4px !important;
font-family: 'Montserrat' !important;
font-style: normal !important;
font-weight: 500 !important;

font-size: 14px !important;
line-height: 24px !important;
color: #717579 !important;
min-height: 48px !important;
min-width: 150px !important;
}
.ant-tabs-nav-wrap { Overflow:visible !important}
.Tabs .ant-tabs{
    overflow: hidden !important;
    overflow-x: scroll !important;
}

/* .ant-tabs-tab-active{
    
} */
.selectedButton.ant-btn:focus{
    border-color: #e3e3e3 !important;
    
}
.hideBottomLine.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more{
    display: none ;
}


.SettingsInputLabel{
    
    font-family: 'Montserrat';
    font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;


color: #717579
}




.SettingsButton{
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    letter-spacing: 0.5px !important;
    height: 60px !important;
    color: #FFFFFF !important;
    background: #0064FF !important;
    border-radius: 4px !important;
    min-width: 127px !important;
}
.SettingsCancelButton{
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    letter-spacing: 0.5px !important;
    height: 60px !important;
    color: #FFFFFF !important;
    background: #E0E0E0 !important;
    border-radius: 4px !important;
    min-width: 127px !important
}

.SettingsAccessTitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;

/* Primary Text */

color: #303134
}

.SettingsBorderLine{
    border: 1px solid #E0E0E0 !important;
}

.SettingsCheckboxTitle{
    font-family: 'Montserrat'!important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 16px !important;
line-height: 20px !important;
/* identical to box height */


color: #717579 !important;
}





.SettingsCheckbox .ant-checkbox-inner{
    border: black !important;

}



.SettingsCancelButton.ant-btn:hover{
   
    border-color: #E0E0E0  !important;
}

.hidedRow{
    opacity: 0.4 !important;
}
.editDesignationButton, .editButton{
    background: #0064FF !important;
    border-radius: 4px;
    font-family: Montserrat;
    font-style: normal;
 
 
 
 
 
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
   
    color: #FFFFFF;
    /* width: 140px; */
}
.editDesignationButton.ant-btn-primary:focus{
    background: #0064FF !important;

}

.unPublishButton, .deleteButton{
    background: #EB5757 !important;
    border-radius: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
   
    border: #EB5757 !important;
}

.doc-button{
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    text-align: center !important;
    letter-spacing: 0.5px !important;
    color: #FFFFFF !important;
    background: #0064FF !important;
    border-radius: 4px !important;
    min-width: 127px !important;
    width: fit-content;
}
.unPublishButton.ant-btn-primary:focus{
    background: #EB5757 !important;


}

.publishButton{
    /* background: #27AE60 !important; */
    /* border-radius: 4px; */
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    /* color: #FFFFFF; */
    border: #27AE60 !important;
    /* width: 105px !important; */
}
.UnpublishButton.ant-btn-primary:focus{
    background: #27AE60 !important;

}
.emptyScreenAdmin{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #828282; 
}
.emptyScreenAdminCenter{
    display: flex;
    height: 50vh;
    align-items: center;
}

site-card-border-less-wrapper{
    padding: 30px;
    background: #ececec;
}

.content{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #4F4F4F;
}
