.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  .emailInput {
    background: #f5f6f8 !important;
   
    box-sizing: border-box !important;
    border-radius: 4px !important;
    height: 55px !important;
  }
  .loginInput.ant-input-affix-wrapper > input.ant-input {
    background: #f5f6f8 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
   
    height: 45px !important;
    max-width: 384px;
  }
  .loginInput.ant-input-affix-wrapper {
    background-color: #f5f6f8 !important;
  }
  .signUpSelector.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: #f5f6f8 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    height: 56px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .loginBtn {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
   
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    letter-spacing: 0.5px !important;
    height: 56px !important;
    color: #ffffff !important;
    background: #0064ff !important;
    border-radius: 4px !important;
    min-width: 178px !important;
  }
  img.imgMain {
    position: absolute;
    left: -22%;

    width: 50%;
    opacity: 0.5;
    top: -2%;
    margin: auto;
  }
  .LandingPageLayout::-webkit-scrollbar {
    display: none !important;
 
  }
  .selectPlaceholder {
    font-family: Montserrat !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    padding-top: 20px !important;
    letter-spacing: 0.4375px;
    color: rgba(39, 39, 39, 0.4) !important;
  }
}
.uploadImageSignUpInput .ant-input-affix-wrapper {
  background: #f5f6f8 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  padding: 0px 11px !important;
}
.uploadImageSignUpInput .ant-input-affix-wrapper > input.ant-input {
  background: #f5f6f8 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  height: 56px !important;
  padding: 0px 11px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

div.imageContainer {
  width: 80%;
  height: 100vh;
  text-align: center;
  left: -37%;
  position: absolute;
}

img.Image {
  width: 678px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0.5;
  left: auto;
}

.spanElement {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.loginDiv.ant-row{

    justify-content: end !important;
    align-items: center !important;
    height: 120vh !important;
    width: 80% !important;
}
.ant-upload.ant-upload-select-picture-card {
  width: 110px;
  height: 110px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
 
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}




.loginInputNe.react-tel-input .form-control{
  width: 100% !important;
  background: #f5f6f8 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  height: 55px !important
}
.MainLoadingIndicator .ant-spin-nested-loading > div > .ant-spin{
  height: 100% !important;
  max-height: unset !important;
}
@keyframes App-logo-spin {
 
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  img.imgMain {
    position: absolute;
    left: -22%;

    width: 50%;
    opacity: 0.5;
    top: 16%;
  }
}
@media (max-width: 850px) {
  img.Image {
    width: 678px;
    display: inline-block;
    vertical-align: middle;
    opacity: 0.5;
    left: auto;
    display: none;
  }
}
@media (max-width: 768px) {
  img.imgMain {
    position: absolute;
    left: -22%;

    width: 50%;
    opacity: 0.5;
    top: 22%;
  }
}
/* div.imageContainer {
  width: 80%;
  height: 88vh;
  text-align: center;
  position: absolute;
  right: 27%;
  top: 8%;
} */

/* img.Image {
  width: 34%;
  display: inline-block;
  vertical-align: middle;
  opacity: 0.5;
  left: auto;
  height: 44%;
} */
