.firstrowevents {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  /* Primary Text */

  color: #303134;
}
.button {
  background: #0064ff;
  border-radius: 4px;
}

.Divider.ant-divider-horizontal.ant-divider-with-text {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  border: #d7d7d7 !important;
  /* color:#717579 !important; */
}

.eventdetailsbackground {
  background: #f5f6f8;
  padding: 24px 24px 0px 24px !important;
}
.Eventtitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;

  /* Primary Text */

  color: #303134;
}
.Eventlocation,
.Eventlocation1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  /* Gray 2 */

  color: #4f4f4f;
}
.Card {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.innext {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #ed8627;
}
.nextbackground {
  background: #fff6d8;
  border-radius: 4px 4px 0px 0px;
  padding: 11px 26px !important;
}

.zeroPaddingCard .ant-card-body {
  padding: 0px 0px 24px 0px !important;
}

.Eventlocation1 {
  font-weight: 400 !important;
}
