.label{
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
/* identical to box height */


/* Secondary Text */

color: #717579;
}
.title{
    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 29px;

/* Primary Text */

color: #303134;

/* Gray 6 */


}

.customScroll{
    overflow: hidden !important;
    overflow-y: scroll !important;
    height: 100vh ;
}



.customScroll::-webkit-scrollbar{
    display: none !important;
}
.customLayout::-webkit-scrollbar{
    display: none !important;
 }