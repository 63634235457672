.CreateTicketDrawerInputText {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
  
    letter-spacing: 0.2px;
  
    /* text-color */
  
    color: #252b42;
  }
  
  .CreateTicketDrawerInputMainText {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  
    /* Primary Text */
  
    color: #303134;
  }
  .DrawerClass {
    background: radial-gradient(
      189.66% 189.66% at 54.03% -21.44%,
      #1a76e4 0%,
      #2accff 62.5%,
      #da8336 93.62%,
      #ee5c9f 100%
    );
    opacity: 0.15;
  }
  .DrawerBorderClass .ant-drawer-content-wrapper {
    /* border-left-width: 1px !important; */
    /* border: 0px 0px 0px 10px !important; */
    border-left: 10px !important;
    border-style: solid !important;
    border-image: linear-gradient(#1976e4, #4cd5ff, #f29d52, #e999bd) 30 !important;
    border-right: 0px !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
  }
  .OpenStatus,
  .ClosedStatus {
    width: 60px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f19c51;
    margin-left: 12px;
    font-family: "Montserrat";
  }
  .ClosedStatus {
    background: #61ccb9;
  }
  .IdTextChatDrawer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #303134;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
  }
  .UserChatBox {
    background: #f5f5f5;
    border: 1px solid #9098b1;
    border-radius: 16px 16px 0px 16px;
    padding: 18px 43px 35px 26px;
    text-overflow: wrap;
    width: fit-content;
  }
  .UserChatBoxText,
  .AdminChatBoxText {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #141414;
  }
  .AdminChatBoxText {
    color: #f5f5f7;
  }
  .AdminChatBox {
    background: #4593ef !important;
    border-radius: 16px 16px 16px 0px;
    padding: 18px 23px 15px 16px;
    width: fit-content;
  }
  .ChatBoxMessager {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .helpAndSupportUpload .ant-upload.ant-upload-select-picture-card {
    height: 30px !important;
    width: 32px !important;
    border: none !important;
  }
  .helpAndSupportUpload .ant-upload {
    height: 27px !important;
    align-items: end !important;
  }
  .helpAndSupportUpload .ant-upload-list-picture-card-container {
    height: 30px !important;
    width: 30px !important;
  }
  .helpAndSupportUpload .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0 !important;
    height: 40px !important;
    border: none !important;
  }
.loginInputNe .form-control,
.loginInputNe .flag-dropdown {
  width: 100% !important;
  background: #f9f9f9 !important;
  box-sizing: border-box !important;
  /* border-radius: 4px !important; */
  border: none !important;
  border-bottom: 2px solid #e6e6e6 !important;
  height: 40px !important;
}
  .DateAndTime {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
  
    /* Glare Gray */
  
    color: #4f5665;
  }
  