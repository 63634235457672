.resendButton.ant-btn{
    padding: 0;
}
.otpInput{
    background: #F5F6F8 !important;
border: 1px solid #DEDEDE !important;
box-sizing: border-box !important;
border-radius: 4px !important;
}
.otpFocusInput{
    border: #0064FF !important;
}
div.ImageContainer{
    width: 80%;
    height: 100vh;
    text-align: center;
 
    left: -37%;
  position: absolute;
  top: -3%;
}