.TermsAndConditionsImageContainer{
    background-image: url("../../assets/LandingPageImage.png");
    background-repeat: no-repeat;
    height: 75vh;
    background-size: cover;
    background-position: center;
    width: 100%;
}

.TermsAndConditionsH1{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 800;

font-size: 58px;
line-height: 80px;

/* identical to box height, or 138% */

text-align: center;
letter-spacing: 0.2px;

/* light-text-color */

color: #FFFFFF;





}

.TermsAndConditionsContentTitle1{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
/* or 188% */


color: #737373;
}

