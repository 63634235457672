.ActivityMainText,.ActivitySubText,.ActivitySubText1{
font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
color: #333333
}

.ActivitySubText{
    color: #828282;
    font-weight: 400;
    font-size: 12px;

}
.ActivitySubText1{

    font-weight: 400;
    color: #BDBDBD;
    line-height: 18px;
}

.ActivityTimelineCircle .ant-timeline-item-head-blue{
    color: #E0E0E0;
    font-size: 18px;

}




.ant-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border: 1px #E0E0E0 dashed;
}





