.Titletext1{
    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 29px;

/* Primary Text */

color: #303134;
}

.Titletext2{
    font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
/* identical to box height */


/* Gray 3 */

color: #828282;

}

.Address{
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 24px;
/* or 171% */


/* Gray 2 */

color: #4F4F4F;
}
.card{
    background: #FFFFFF;
box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12);
border-radius: 4px;
}


.border.ant-divider-horizontal{
    border: 1px solid #F2F2F2;
    margin: 0 !important;
}
.antZeroPadding.ant-col{
    padding: 0 !important;
}

.emptyScreen{
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 29px;

/* Primary Text */

color: #303134;
}

.PhoneAndEmail{
    font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;

/* Gray 3 */

color: #828282;
}

.paddingBorder.ant-divider-horizontal{
    border: 1px solid #F2F2F2;
    margin: 10px 0px 0px 0px !important;
}