.acceptButton,.rejectButton{
    background: #0064FF !important;
    border-radius: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
}
.acceptButton.ant-btn-primary:focus{
  
    background: #0064FF !important;
}

.rejectButton{
    background: #EB5757 !important;
    border-color: #EB5757 !important;
}
.rejectButton.ant-btn-primary:focus{
    background: #EB5757 !important;
}