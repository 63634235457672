:root {
    --light-blue: #2accff;
    --purple: #ee5c9f;
  }
  .CommunityFeedsTabs {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #a6a6a6 !important;
  }
  
  .CommunityFeedsTabsLength {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
  
    color: #000000 !important;
  }
  
  .CommunityFeedsContainer .ant-tabs-ink-bar {
    background: var(--light-blue);
  }
  
  .CommunityTextCardTitle,
  .communityCardDate,
  .communityCardDescription {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }
  .communityCardDate {
    color: #27272766;
  }
  .communityCardDescription {
    font-size: 24px;
    line-height: 28px;
  }
  .communityCardDescription1 {
   
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
text-align: center;
text-transform: capitalize;

color: #FFFFFF;


/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;
  }
  .CommunityTextCardTitle1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
  
    color: #7d7987;
  }
  .CommunityTextCardTitle2 {
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 37px;
/* identical to box height */
text-align: center;
text-transform: capitalize;

background: linear-gradient(180deg, #B17602 0%, rgba(162, 118, 35, 0.5) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
  }
  .CommunityTextCardTitle3{
    font-family: 'Montserrat';

font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 27px;

text-align: center;

/* Grey */
color: #F5F5F7;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
  }
  .CommunityLinkCardAnchor {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 18px;
    /* or 120% */
  
    text-decoration-line: underline !important;
  
    /* S1 Light */
  
    color: #4fd6ff !important;
  }
  .player-wrapper {
    position: relative !important;
    border-radius: 40px !important;
  }
  .react-player {
    border-radius: 4px !important;
    overflow: hidden !important;
  }
  .communityCard {
    background: #ffffff;
    /* Gray 6 */
  
    border: 1px solid #999;
  
    border-radius: 20px;
  }
  .communityCard2 {
    background: #000000;
    border-radius: 12px;
   
   border-color: linear-gradient(180deg, #B17602 0%, rgba(162, 118, 35, 0.5) 100%);
    
  }
  .communityCard3{
    background: linear-gradient(180deg, #A145BA 0%, #4598ED 100%);
    border-radius: 4px;
    }
  .FeedsStories {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    /* identical to box height, or 88% */
  
    text-align: center;
  
    color: #000000;
  }
  .FetchButton {
    /* margin: 15px 41px; */
    background: var(--light-blue);
    border-radius: 100px;
  }
  .LatestTransactionText {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
  
    /* Primary Text */
  
    color: #303134;
  }
  .SelectPaymentTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.007em;
  
    color: #ffffff;
  }
  .SelectPaymentAmount {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.007em;
  
    /* Grey */
  
    color: #f5f5f7;
  }
  .SelectPaymentCard .ant-card-body {
    width: 372px;
    padding: 0 !important;
  }
  .SelectPaymentTitle1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    text-transform: capitalize;
    /* Main Color 2 */
    color: #39475f;
  }
  
  .EmptyScreenCommunityCards {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .EmptyScreenCommunityCards .ant-empty-image {
    height: 50px !important;
  }
  
  /* border-color:"#999" all card border color */
  /* border-radius:20px and padding:20px card*/
  /* Name ->   font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: '#000000' */
  