.header{
    margin-top: 30px;
    margin-left: 14%;
    margin-right: 15%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
}
.samajTitle{    
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;

font-size: 24px;


line-height: 32px;
letter-spacing: 0.1px;

color: #FFFFFF
}

.loginButton{
    font-family: 'Montserrat';

    font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 22px;
/* identical to box height, or 157% */

text-align: right;
letter-spacing: 0.2px;

/* light-text-color */

color: #FFFFFF
}

.becomeAMemberButton{
    background: #347AF0 !important;
border-radius: 25px !important;
color:#FFFFFF !important;


font-family: 'Montserrat';


width: 214px !important;
height: 52px !important;
border-color: #347AF0;
}

.aboutTitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
   
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    
    text-align: center;
    letter-spacing: 0.2px;
    
    /* light-text-color */
    
  
    color: #FFFFFF
}

.landingScreenImage{
    
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
  
    z-index: 0;
}




.Title1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 4vw;
    line-height: 80px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    display: flex;
    justify-content: flex-end ;
    width: 53%;
    height: 54vh;



    align-items: flex-end ;
}
.subTitle1{
    font-family: 'Montserrat';



    font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
/* identical to box height, or 150% */

text-align: center;
letter-spacing: 0.2px;

/* light-text-color */



color: #FFFFFF;
display: flex;
justify-content: center;
margin-top: 2.5%;
}
.subTitleLoginButton{
    width: 115px;
    height: 52px;
    
    /* Main Primary Color */
    
    background: #347AF0;
   
    border-radius: 37px;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 22px;
/* identical to box height, or 157% */


text-align: center;
letter-spacing: 0.2px;

/* light-text-color */

color: #FFFFFF;
border-color: #347AF0;
}
.subTitleLearnMoreButton{
height: 52px;

/* light-text-color */

border: 1px solid #FFFFFF;
border-radius: 37px;
background: transparent;
color: #FFFFFF;
font-style: normal;
font-weight: 700;



font-size: 14px;
line-height: 22px;
/* identical to box height, or 157% */

text-align: center;
letter-spacing: 0.2px;

/* light-text-color */

color: #FFFFFF
}
.landingPageCard{
    background: #FFFFFF;
/* accentued-drop-shadow */

box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
border-radius: 50px;
}
.cardTitle{



    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

letter-spacing: 0.1px;

/* text-color */

color: #252B42
}
.cardTitle2{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* or 143% */

letter-spacing: 0.2px;

/* second-text-color */

color: #737373
}
.documentsTitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

text-align: center;
letter-spacing: 0.1px;

/* text-color */

color: #252B42
}

.documentsSubTitle{
    font-family: 'Montserrat';

    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* or 143% */

text-align: center;
letter-spacing: 0.2px;

/* second-text-color */

color: #737373
}
.documentsSection{
    height: 435px;
   
   
   
   
   
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.documentsSection .ant-card-bordered{
    border: none !important;
}
.contactNumberSection{
    background: #252B42;
    height: 1030px;
}
.contactNumberSection .ant-card-body{
        padding: 0;
}




.contactNumberSection .ant-input{

    background: #F9F9F9 !important;
    border: 1px solid #E6E6E6 !important;
    border-radius: 5px !important;
    height: 50px !important;
}
.contactSectionInputTitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
/* identical to box height, or 171% */

letter-spacing: 0.2px;

/* text-color */


color: #252B42

}
.contactSectionButton{
    background: #347AF0;
border-radius: 100px;
width: 100%;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 22px;
/* identical to box height, or 157% */

text-align: center;
letter-spacing: 0.2px;

/* light-text-color */

color: #FFFFFF;
height: 52px;



}
.contactNumberSection .ant-card{
    border-radius: 50px !important;

}
.footer1Section{
    height:        272px;
    display:flex;
    align-items: center;


    background: #FFFFFF;


   justify-content: center; 
}
.footer1Section a{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    
    letter-spacing: 0.2px;
    
    /* second-text-color */
    
    color: #737373
}

.footer2SectionTitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
/* identical to box height, or 171% */


letter-spacing: 0.2px;

/* second-text-color */

color: #737373
}
.footer2Section{
    background: #FAFAFA;   
        height: 72px;
}
.hamBurgerMenuSubTitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 45px;
/* identical to box height, or 150% */

text-align: center;
letter-spacing: 0.2px;

/* second-text-color */

color: #737373;
}
/* .ant-drawer-content-wrapper{
    height: 324px !important
} */
.LandingPageImageContainer{
    background-image: url("../../assets/LandingPageImage.png");
    background-repeat: no-repeat;
    height: 100%;
    background-size: cover;
    background-position: center;
}
.LandingPageCardResponsive{
    bottom: calc(4vh + -39%);    
}
@media (max-width: 1327px) {
   
    .LandingPageCardResponsive{
        bottom: calc(4vh + -52%);
    }   
 



}
@media (max-width: 1100px) {
   
    .header{
  
        margin-top: 30px !important;
        margin-left: 8% !important;
    margin-right: 7% !important;      
    }
 

}



@media (max-width: 910px) {
   
   
    .Title1{
        font-size: 40px;
    }
 


}


@media (max-width: 575px) {
    .contactNumberSection .ant-card{
        border-radius: 0px !important;
    }
}
