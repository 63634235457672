.editButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 156px;
  height: 48px;
  background: #347af0;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #f5f5f7;
}
.cancelButton {
  background: #ffffff;
  box-shadow: 0px 1px 120px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #347af0;
  width: 156px;
  height: 48px;
}
.addCommunity {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
/* padding-bottom: 9px; */
/* padding-top: 80px; */
  /* Primary Text */
  color: #303134;
}
.community-Title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  cursor: pointer;
  /* identical to box height */
  /* margin-top: 5px; */
  /* Primary Text */
  color: #303134;
}
.card-community{
    background: #FFFFFF;
box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12);
border-radius: 4px;

/* height: 100px; */
}
.publishedColor{
  color: rgb(176, 22, 22);
  font-family: 'Montserrat' !important;
font-style: normal;
font-weight: 700 !important;
font-size: 14px;
line-height: 17px;
}
.unpublishedColor{
  color: rgb(13, 133, 81);
  font-family: 'Montserrat' !important;
font-style: normal;
font-weight: 700 !important;
font-size: 14px;
line-height: 17px;
}
.publishDetail{
font-family: 'Montserrat' !important;
font-style: normal;
font-weight: 700 !important;
font-size: 14px;
line-height: 17px;
/* identical to box height */

/* Gray 3 */
color: #828282 !important;
}
.ant-typography ul {
  list-style-type: disc;
}
.card-community2{
  background: #FFFFFF;
/* Button Shadow */
box-shadow: 6px 6px 25px rgba(42, 139, 242, 0.15), 4px 4px 25px rgba(42, 139, 242, 0.05), 10px 6px 25px rgba(42, 139, 242, 0.15);
border-radius: 4px;
}
.breadcrumCommunity{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
cursor: pointer;
/* identical to box height */
/* text-decoration-line: underline; */

/* Main Primary Color */
color: #347AF0;
}
.breadcrumUsers{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
cursor: pointer;
/* identical to box height */

/* Card Linear */
background: linear-gradient(115.04deg, #000000 4.33%, rgba(0, 0, 0, 0.9) 37.68%, rgba(0, 0, 0, 0.85) 69.91%, #000000 105.58%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
/* text-fill-color: transparent; */

}
.detailsPage{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;

/* Primary Text */
color: #303134;

}
.detailsPage2{
 
font-family: 'Montserrat' !important;
font-style: normal;
font-weight: 700 !important;
font-size: 14px;
line-height: 17px;
/* identical to box height */

/* Gray 3 */
color: #828282 !important;

}
.community{
padding: 2%;}