.communityTitle{
    font-family: Montserrat !important;
font-style: normal !important;
font-weight: bold !important;
font-size: 24px !important;
line-height: 29px !important;
letter-spacing: 0.3px !important;

color: #272727 !important;
  }
.communityBtn .ant-btn {
    border: 1px solid #0064FF !important;
  }

.communityRadioBtn{
    font-family: Montserrat !important;
   
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px;
    height: 56px !important;
    width: 100px !important;
    
    /* identical to box height */
    
    letter-spacing: 0.4375px !important;
    
    /* Primary Color */
    
    color: #0064FF !important;
  }

  .communityBtn{
    background: #F5F6F8 !important;
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 4px;
  
  }
  .communityContent{
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 23px;





/* or 163% */
letter-spacing: 0.2625px;

color: rgba(39, 39, 39, 0.85);


  }
  .headerTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.3px;
    color: #272727 !important;
  }
  .loginBtn {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    letter-spacing: 0.5px !important;
    height: 56px !important;
    color: #FFFFFF !important;
    background: #0064FF !important;
    border-radius: 4px !important;
    min-width: 178px !important;
  }
  .pageContent{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;

    /* or 163% */
    letter-spacing: 0.2625px;

    color: rgba(39, 39, 39, 0.85);
  }
  #back{
      align-items: center;
  }
  #backbtn{
    padding: 13px;
 
 
 
 
  }

.communityTitle1{
  font-family: 'Montserrat' !important;
font-style: normal !important;
font-weight: 700 !important;
font-size: 20px !important;
line-height: 24px !important;
}
